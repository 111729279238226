<script setup>
import { ref } from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import TextBlock from "@/components/additional/TextBlock.vue";

const items = ref([
  {
    title: "Expertise",
    text: "With years of experience in intellectual property, our team possesses the knowledge and skills necessary to protect and enhance our beneficiaries' assets",
    image: "block2.jpg",
    reverse: false,
  },
  {
    title: "Efficiency",
    text: "With years of experience in intellectual property, our team possesses the knowledge and skills necessary to protect and enhance our beneficiaries' assets",
    image: "block1.jpg",
    reverse: true,
  },
  {
    title: "Innovation",
    text: "We are committed to fostering an innovative environment within our company and for our clients, helping them stay ahead in their respective industries",
    image: "block3.jpg",
    reverse: false,
  },
])
</script>

<template>
  <section class="section-block choose-us">
    <div class="container relative">
      <SectionTitle>why choose us?</SectionTitle>
      <p class="section-bg-text">why choose us?</p>
      <div class="blocks flex">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="block flex flex-col justify-between"
          :class="{'col-reverse second': item.reverse}"
        >
          <TextBlock
            :title="item.title"
            :text="item.text"
            :reverse="item.reverse"
          />
          <img
            :src="require(`@/assets/img/${item.image}`)"
            alt="block image"
            v-motion
            :initial="{opacity: 0, y: -10}"
            :visibleOnce="{opacity: 1, y: 0, transition: { duration: 1000, delay: 100 + index * 50 }}"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.choose-us {
  transform: translateY(-100px);
  padding: 100px 0;
  background: linear-gradient(180deg, rgba(147, 40, 175, 0.00) -194.83%, rgba(0, 0, 0, 0.30) 26.23%);
  .container {
    overflow: visible;
  }
  .section-bg-text {
    font-size: clamp(40px, 16vw, 230px);
    letter-spacing: 12.7px;
    top: -16%;
    left: 50%;
    transform: translateX(-50%);
  }
  .blocks {
    gap: 22px;
    margin-top: 80px;
    .block {
      flex-grow: 1;
      gap: 96px;
      &.second {
        gap: 60px;
      }
      img {
        border-radius: 4px;
        overflow: hidden;
        width: 100%;
      }
      .text-block {
        margin: 0 24px;
      }
    }
  }
}

@media (max-width: 768px) {
  .choose-us {
    transform: translateY(0);
    background: none;
    padding: 82px 0 100px;
    .blocks {
      flex-direction: column;
      margin-top: 48px;
      gap: 60px;
      .block {
        gap: 20px;
        &:nth-child(2n + 1) {
          flex-direction: column-reverse;
        }
      }
    }
    .section-bg-text {
      font-size: clamp(40px, 16vw, 230px);
      letter-spacing: 2.5px;
      top: -3%;
    }
  }
}
</style>