<script setup>
import { ref, watch } from "vue";
import {useRoute} from "vue-router";
import Logo from "@/components/common/Logo.vue";
import Burger from "@/components/common/Burger.vue";
import Menu from "@/components/common/Menu.vue";
import Modal from "@/components/ui/Modal.vue";
import Button from "@/components/ui/Button.vue";

const route = useRoute();

const isMenu = ref(false);
const isModal = ref(false);

watch(() => isMenu.value, () => {
  if(isMenu.value) document.body.classList.add('no-scroll');
  else document.body.classList.remove('no-scroll');
})

</script>

<template>
  <header>
    <div class="container flex align-center justify-between">
      <RouterLink to="/">
        <Logo
          v-motion
          :initial="{opacity: 0, y: -20}"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 0 }}"
        />
      </RouterLink>
      <Button
        @click="isModal = true"
        class="mob-btn"
        v-if="route.path === '/privacy'"
        v-motion
        :initial="{opacity: 0, y: -10}"
        :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 0 }}"
      >Get In Touch</Button>
      <Burger
        @click="isMenu = true"
        v-motion
        :initial="{opacity: 0, y: -10}"
        :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 0 }}"
      />
    </div>
    <Menu :isOpen="isMenu" @close="isMenu = false"></Menu>
    <Modal :isOpen="isModal" @close="isModal = false"></Modal>
  </header>
</template>

<style lang="scss">
header {
  padding: 28px 0;
  .mob-btn {
    display: none;
  }
}

@media (max-width: 768px) {
  header {
    .mob-btn {
      display: flex;
      height: 40px;
      width: 40vw;
      max-width: 140px;
      font-size: 14px;
      font-weight: 700;
      line-height: 26px;
      padding: 7px 14px;
      gap: 12px;
      white-space: nowrap;
      border-radius: 8px;
      margin: 0 16px 0 auto;
      &::before {
        border-radius: 7px;
      }
      svg {
        min-width: 16px;
      }
    }
  }
}
</style>