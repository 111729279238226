<script setup>
import { ref } from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import SectionTemplate from "@/components/additional/SectionTemplate.vue";

const data = ref({
  title: "Our vision is to revolutionize",
  text: "Our vision is to revolutionize the landscape of intellectual property protection through an efficient, fast, and modern service. We strive to streamline procedures, leveraging cutting-edge information technology systems and a robust legislative framework.",
  text2: "By doing so, we aim to cultivate an innovative entrepreneurial culture that drives progress and growth. We envision a future where intellectual property management is seamless, transparent, and conducive to fostering creativity and innovation.",
  image: "figure3.png",
})
</script>

<template>
  <section class="section-block vision-section">
    <div class="container relative">
      <SectionTitle>our vision</SectionTitle>
      <SectionTemplate
          :title="data.title"
          :image="data.image"
          :textPosition="data.textPosition"
          :text="data.text"
          :text2="data.text2"
          :width="400"
          :margin="0"
      />
      <p class="section-bg-text">our vision</p>
    </div>
  </section>
</template>

<style lang="scss">
.vision-section {
  padding: 80px 0 10px;
  transform: translateY(-100px);
  .container {
    overflow: visible;
  }
  .section-bg-text {
    letter-spacing: 12.7px;
    font-size: clamp(40px, 26vw, 370px);
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .vision-section {
    transform: translateY(0);
    padding: 40px 0 0;
    .info-section {
      padding: 48px 0 0;
    }
    .section-bg-text {
      font-variant: all-small-caps;
      letter-spacing: 3.8px;
      font-size: clamp(40px, 25vw, 370px);
      top: -8%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>