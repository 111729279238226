<script setup>
import { ref } from "vue";

const textWords = ref("Our vast experience and comprehensive services ensure that our clients’ trademarks are not only safeguarded but also leveraged to enhance their brand value and market presence. Partner with Ericius Investments to secure your trademarks and drive your brand’s success".split(" "));
</script>

<template>
  <section class="note-section">
    <div class="container">
      <div class="note">
        <p>
          <span
            v-for="(word, index) in textWords"
            :key="index"
            class="word"
            v-motion
            :initial="{opacity: 0}"
            :visibleOnce="{opacity: 1, transition: { duration: 1000, delay: 400 + index * 15 }}"
          >
            {{ word }}
          </span>
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.note-section {
  padding: 40px 0 60px;
  transform: translateY(-100px);
  .note {
    padding: 40px 0;
    p {
      color: $white;
      text-align: center;
      font-size: 26px;
      line-height: 44px;
      width: 85%;
      max-width: 964px;
      margin: 0 auto;
      span {
        display: inline-block;
        white-space: nowrap;
        margin-right: 6px;
      }
    }
  }
}

@media (max-width: 768px) {
  .note-section {
    transform: translateY(0);
    padding: 40px 0 110px;
    .note {
      padding: 20px 0 0;
      p {
        font-size: 22px;
        line-height: 38px;
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>