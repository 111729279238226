<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import SectionTitle from "@/components/additional/SectionTitle.vue";
import ServiceCard from "@/components/additional/ServiceCard.vue";
import SliderControl from "@/components/additional/SliderControl.vue";
import 'swiper/css';

const cards = ref([
  {
    background: "card1_bg.jpg",
    title: "Intellectual property monitoring",
    text: "We provide comprehensive monitoring services for Trademarks, Patents, and Industrial Designs to ensure our beneficiaries' rights are protected and any potential infringements are promptly addressed",
    image: "card1.jpg"
  },
  {
    background: "card2_bg.jpg",
    title: "Information and support",
    text: "Our team of experts offers detailed information and support on all aspects of intellectual property management, helping clients navigate the complexities of intellectual property law with ease",
    image: "card2.jpg"
  },
  {
    background: "card3_bg.jpg",
    title: "Modern IT solutions",
    text: "We utilize state-of-the-art information technology systems to streamline our processes, making our services more efficient and user-friendly",
    image: "card3.jpg"
  },
  {
    background: "card4_bg.jpg",
    title: "Legislative framework guidance",
    text: "We keep our clients informed about the latest developments in intellectual property legislation, ensuring they are always compliant with current laws and regulations",
    image: "card4.jpg"
  }
])

const swiperInstance = ref(null);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};
</script>

<template>
  <section class="section-block section-services">
    <div class="container relative">
      <p class="section-bg-text">our services</p>
      <SectionTitle>our services</SectionTitle>
      <div
        class="category_container"
        v-motion
        :initial="{opacity: 0, x: 100}"
        :visibleOnce="{opacity: 1, x: 0, transition: { duration: 900, delay: 200 }}"
      >
        <ServiceCard
            v-for="(card, index) in cards"
            :key="index"
            :title="card.title"
            :image="card.image"
            :background="card.background"
            :text="card.text"
        />
      </div>
      <div class="slider">
        <swiper
            class="slider"
            ref="swiperRef"
            slides-per-view="auto"
            :space-between="16"
            @swiper="onSwiper"
            :modules="[Navigation]"
        >
          <swiper-slide v-for="(card, id) in cards" :key="id">
            <ServiceCard
              :title="card.title"
              :image="card.image"
              :background="card.background"
              :text="card.text"
              :active="swiperInstance?.activeIndex === id"
            />
          </swiper-slide>
        </swiper>
        <div class="control flex">
          <SliderControl
              @click="goPrev"
              :disabled="swiperInstance?.isBeginning"
          ></SliderControl>
          <SliderControl
              @click="goNext"
              btnType="next"
              :disabled="swiperInstance?.isEnd"
          ></SliderControl>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.section-services {
  padding: 80px 0;
  transform: translateY(-100px);
  .container {
    overflow: visible;
  }
  .slider {
    display: none;
  }
  .swiper {
    overflow: clip;

    .swiper-slide {
      width: auto !important;
      height: auto;
      max-width: 300px;
    }
  }
  .section-bg-text {
    font-size: clamp(40px, 19.5vw, 280px);
    letter-spacing: 12.7px;
    top: -18%;
    left: 50%;
    transform: translateX(-50%);
  }
  .category_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px;
    width: 100%;
    height: 100%;
    margin-top: 80px;
  }
}

@media (max-width: 1024px) {
  .section-services {
    transform: translateY(0);
    .category_container {
      display: none;
    }
    .slider {
      margin-top: 48px !important;
      display: block;
    }
    .swiper {
      margin: 0;
    }
    .section-bg-text {
      font-size: clamp(40px, 20vw, 280px);
      letter-spacing: 3.1px;
      top: -9%;
    }
    .control {
      margin: 40px 0 20px;
      gap: 18px;
      .slide-control-btn {
        font-size: 60px;
      }
    }
  }
}
@media (max-width: 768px) {
  .section-services {
    padding: 50px 0 0;
  }
}
</style>