<template>
  <div
    class="section-title"
    v-motion
    :initial="{opacity: 0}"
    :visibleOnce="{opacity: 1, transition: { duration: 1000, delay: 100 }}"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
      <g filter="url(#filter0_f_133_14)">
        <circle cx="38" cy="38" r="18" fill="#9328AF" fill-opacity="0.59"/>
      </g>
      <defs>
        <filter id="filter0_f_133_14" x="0" y="0" width="76" height="76" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_133_14"/>
        </filter>
      </defs>
    </svg>
    <p><slot></slot></p>
  </div>
</template>

<style scoped lang="scss">
.section-title {
  position: relative;
  svg {
    position: absolute;
    top: -25px;
    left: -35px;
  }
  p {
    color: $white;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16px;
    position: relative;
    z-index: 2;
    white-space: nowrap;
  }
}
</style>
<script setup lang="ts">
</script>