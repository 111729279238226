<script setup>
import { ref } from "vue";

const props = defineProps({
  modelValue: String,
  textarea: Boolean,
  placeholder: String,
  isError: Boolean,
  required: Boolean,
})
const emit = defineEmits(['update:modelValue']);

const isTyping = ref(false);

const handleFocus = () => {
  isTyping.value = true;
};

const updateModelValue = (value) => {
  isTyping.value = true;
  emit('update:modelValue', value);
};
</script>

<template>
  <div class="input" :class="{'error': isError, 'filled': modelValue !== '' && !isTyping}">
    <span class="placeholder" :class="{'filled': modelValue !== ''}">{{ placeholder }}</span>
    <input
      v-if="!textarea"
      :value="modelValue"
      @input="updateModelValue($event.target.value)"
      @focus="handleFocus"
      @blur="isTyping = false"
      :required="required"
    />
    <textarea
      v-else
      :value="modelValue"
      @input="updateModelValue($event.target.value)"
      @focus="handleFocus"
      @blur="isTyping = false"
    ></textarea>
  </div>
</template>

<style scoped lang="scss">
.input {
  border-radius: 10px;
  border: 1px solid rgba(217, 217, 217, 0.12);
  padding: 8px 12px;
  position: relative;
  transition: all .3s ease;
  &:hover {
    border: 1px solid rgba(205, 87, 235, 0.40);
  }
  &.filled {
    &:hover {
      border: 1px solid $purple-200;
      .placeholder {
        color: $purple-200;
      }
    }
    .placeholder {
      color: rgba(205, 87, 235, 0.59);
      opacity: 1;
    }
  }
  &.error {
    border: 1px solid rgba(205, 87, 235, 0.40);
    .placeholder {
      color: rgba(205, 87, 235, 0.59);
    }
  }
  .placeholder {
    position: absolute;
    top: 8px;
    left: 12px;
    color: #FFFEF8;
    font-variant-numeric: oldstyle-nums proportional-nums;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    opacity: 0.4;
    transition: all .2s ease;
    &.filled {
      color: $purple-200;
    }
  }
  input,
  textarea {
    border: none;
    outline: none;
    background: none;
    height: 100%;
    color: $white;
    font-variant-numeric: oldstyle-nums proportional-nums;
    font-size: 14px;
    line-height: 20px;
    padding-top: 16px;
    caret-color: $purple;
    width: 100%;
  }
  textarea {
    resize: none;
    min-height: 100px;
  }
}

@media (max-width: 768px) {
  .input {
    input, textarea {
      font-size: 16px;
    }
  }
}
</style>