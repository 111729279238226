<script setup>
import { ref } from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import SectionTemplate from "@/components/additional/SectionTemplate.vue";

const data = ref({
  title: "At Ericius Investments, our mission is clear",
  text: "To enhance the protection of intellectual property rights for our beneficiaries. We achieve this by delivering the highest level of service, coupled with vigilant monitoring of Trademarks, Patents, and Industrial Designs.",
  text2: "Our commitment is to empower our clients with the tools and knowledge they need to safeguard their intellectual property effectively.",
  image: "figure2.png",
  textPosition: "right",
})
</script>

<template>
  <section class="section-block mission-section">
    <div class="container relative">
      <SectionTitle>our mission</SectionTitle>
      <SectionTemplate
          :title="data.title"
          :image="data.image"
          :textPosition="data.textPosition"
          :text="data.text"
          :text2="data.text2"
          :width="375"
          :margin="8"
      />
      <p class="section-bg-text">our mission</p>
    </div>
  </section>
</template>

<style lang="scss">
.mission-section {
  padding: 120px 0 0;
  transform: translateY(-100px);
  .container {
    overflow: visible;
  }
  .section-bg-text {
    font-size: clamp(40px, 21.5vw, 300px);
    letter-spacing: 12.7px;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .mission-section {
    transform: translateY(0);
    padding: 60px 0 0;
    .info-section {
      padding: 48px 0 0;
    }
    .section-bg-text {
      font-size: clamp(40px, 22vw, 300px);
      top: -8%;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 900;
      font-variant: all-small-caps;
      letter-spacing: 3.4px;
    }
  }
}
</style>