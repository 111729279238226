<script setup>
import { ref, watch, onUnmounted } from "vue";
import Input from "@/components/ui/Input.vue";
import Button from "@/components/ui/Button.vue";

const emit = defineEmits(['response']);

const data = ref({
  name: "",
  email: "",
  message: "",
  subject: ""
})

const isChecked = ref(false);
const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);

watch(isSent, () => {
  if(!isSent.value) resetForm();
});

const validate = () => {
  let error = false;
  Object.entries(data.value).map(([key, value]) => {
    if(key === 'name' && value.toString().trim().length == 0) error = true;
    if(key === 'email' && !emailRegex.value.test(value)) error = true;
  });
  return error;
}

const resetForm = () => {
  data.value = {
    name: "",
    email: "",
    subject: "",
    message: "",
  }
  isSent.value = false;
  isChecked.value = false;
}

const fetchData = (formdata) => {
  const request = {
    method: 'POST',
    body: formdata,
    mode: 'no-cors',
  }

  fetch('https://license.darmius.kz/mailsend', request)
      .then(response => {
        isSent.value = true;
        isSuccess.value = true;
        emit('response', true);
        resetForm();
      })
      .catch(e => {
        isSent.value = true;
        isSuccess.value = false;
        emit('response', false);
      })
}

const send = () => {
  isError.value = validate();
  if(!isError.value) {
    const formdata = new FormData();
    formdata.append('id', 'Ericius Consulting');
    formdata.append('name', 'User name: ' + data.value.name);
    formdata.append('email', 'User email: ' + data.value.email);
    formdata.append('emailto', "info@ericius.net");
    formdata.append('message', 'Message: ' + data.value.message);
    formdata.append('subject', 'Ericius Website Request: ' + data.value.subject);

    fetchData(formdata)
  }
}

onUnmounted(() =>{
  resetForm();
});
</script>

<template>
  <div class="f-form">
    <div class="f-form__top flex flex-col">
      <h4>Ready to protect your intellectual property <span>with the best in the business?</span></h4>
      <p>Contact Ericius Investments today to learn more about how we can support your intellectual property needs.</p>
    </div>
    <form class="flex flex-col" id="formm" @submit.prevent="send">
      <Input
        placeholder="Your name"
        type="text"
        v-model="data.name"
        :isError="isError && data.name.toString().trim().length == 0"
        required
      />
      <Input
        placeholder="Your email"
        type="email"
        v-model="data.email"
        required
        :isError="isError && !emailRegex.test(data.email)"
      />
      <Input
        placeholder="Subject"
        type="text"
        v-model="data.subject"
      />
      <Input
        placeholder="Your message"
        type="text"
        textarea
        v-model="data.message"
      />
      <div class="flex flex-col bottom">
        <label class="custom-checkbox flex align-center" :class="{'checked': isChecked}">
          <input type="checkbox" id="ch1" name="check" v-model="isChecked" required/>
          <span class="checkmark"></span>
          <span class="check-text transparency-text second">
            I have read and accept the <RouterLink class="no-underline" to="/privacy">Privacy Policy</RouterLink>.
          </span>
        </label>
        <Button
          type="submit"
        >Send a request</Button>
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
.f-form {
  width: 100%;
  max-width: 625px;
  padding: 32px 40px;
  border-radius: 16px;
  background: #0C0C0C;
  &__top {
    color: $white;
    gap: 14px;
    h4 {
      font-size: 26px;
      font-weight: 700;
      line-height: 35px;
      span {
        font-weight: 400;
      }
    }
    p {
      color: #FFFEF8;
      font-size: 14px;
      line-height: 26px;
      opacity: 0.7;
    }
  }
  .bottom {
    gap: 32px;
  }
}
form {
  margin-top: 32px;
  gap: 16px;
  button {
    max-width: 100%;
    height: 66px;
    border-radius: 12px;
    &::before {
      border-radius: 11px;
    }
  }
}
.custom-checkbox {
  position: relative;
  cursor: pointer;
  align-items: flex-start !important;
  transition: all .2s ease;
  &.checked:hover {
    opacity: 0.7;
  }
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  input[type="checkbox"]:checked + .checkmark {
    border: 1px solid #9829B1;
    background-size: 50% 50%;
    border-radius: 6px;
    background-color: #9829B1;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("@/assets/icons/check.svg");
    & + .check-text {
      color: $white;
      a {
        color: $white;
      }
    }
  }
  .check-text {
    font-size: 16px;
    line-height: 24px;
    margin-left: 8px;
    color: rgba(217, 217, 217, 0.50);
    a {
      color: rgba(217, 217, 217, 0.50);
      text-decoration: none;
      font-size: 16px;
      line-height: 24px;
      transition: all 0s ease;
    }
  }
  .checkmark {
    display: inline-block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: 6px;
    border: 1px solid rgba(217, 217, 217, 0.20);
    background: none;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .f-form {
    max-width: 480px;
  }
}

@media (max-width: 768px) {
  .f-form {
    max-width: 100%;
    padding: 40px 20px;
    &__top {
      gap: 12px;
    }
    .custom-checkbox .check-text {
      font-size: 14px;
      line-height: 20px;
      a {
        font-size: 14px;
        line-height: 20px;
        opacity: 1;
      }
    }
  }
}
</style>