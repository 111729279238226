<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import SectionTitle from "@/components/additional/SectionTitle.vue";
import IconBlock from "@/components/additional/IconBlock.vue";
import SliderControl from "@/components/additional/SliderControl.vue";
import 'swiper/css';

const blocks = ref([
  {
    icon: "search",
    title: "Infringement monitoring and enforcement",
    text: "We utilize advanced tools to monitor the market for potential trademark infringements. If an infringement is detected, our legal team takes swift and effective action to enforce our clients' rights.We utilize advanced tools to monitor the market for potential trademark infringements. If an infringement is detected, our legal team takes swift and effective action to enforce our clients' rights."
  },
  {
    icon: "book",
    title: "Trademark registration",
    text: "We assist our clients in the registration of their trademarks, ensuring compliance with all legal requirements and smooth processing through national and international trademark offices."
  },
  {
    icon: "bag",
    title: "Portfolio management",
    text: "Our dedicated team continuously monitors and manages our extensive portfolio, ensuring all trademarks are up-to-date, renewed timely, and protected against potential infringements."
  },
  {
    icon: "security",
    title: "Strategic brand protection",
    text: "Beyond registration, we provide strategic advice on brand protection, helping our clients to develop robust brand strategies that include trademark use, licensing, and expansion into new markets."
  },
  {
    icon: "network",
    title: "Global reach",
    text: "With a network of international partners, we manage trademarks across multiple jurisdictions, ensuring that our clients’ brands are protected globally."
  },
])

const textWords = ref("At Ericius Investments, we pride ourselves on our extensive and diverse portfolio of trademarks".split(" "));
const text2Words = ref("Our comprehensive approach to trademark management ensures that our beneficiaries' brands are not only protected but also strategically positioned to maximize their market impact. Here’s how we manage and leverage our trademark portfolio".split(" "));

const swiperInstance = ref(null);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};
</script>

<template>
  <section class="section-block portfolio-section">
    <div class="container portfolio relative">
      <SectionTitle>our trademark portfolio</SectionTitle>
      <p class="section-bg-text">our trademark <br/> portfolio</p>
      <div class="portfolio__top flex">
        <div class="texted flex flex-col">
          <p class="texted__main">
            <span
              v-for="(word, index) in textWords"
              :key="index"
              class="word"
              v-motion
              :initial="{opacity: 0}"
              :visibleOnce="{opacity: 1, transition: { duration: 600, delay: 100 + index * 20 }}"
            >
              {{ word }}
            </span>
          </p>
          <p class="texted__secondary">
            <span
              v-for="(word, index) in text2Words"
              :key="index"
              class="word"
              v-motion
              :initial="{opacity: 0}"
              :visibleOnce="{opacity: 1, transition: { duration: 600, delay: 400 + index * 10 }}"
            >
              {{ word }}
            </span>
          </p>
        </div>
        <IconBlock
          :icon="blocks[0].icon"
          :title="blocks[0].title"
          :text="blocks[0].text"
          v-motion
          :initial="{opacity: 0}"
          :visibleOnce="{opacity: 1, transition: { duration: 1000, delay: 100 }}"
        />
      </div>
      <div class="portfolio__bottom flex justify-between">
        <IconBlock
          v-for="(item, index) in blocks.slice(1)"
          :key="index"
          :icon="item.icon"
          :title="item.title"
          :text="item.text"
          v-motion
          :initial="{opacity: 0}"
          :visibleOnce="{opacity: 1, transition: { duration: 1000, delay: 300 + index*50 }}"
        />
      </div>
      <div class="mob-block">
        <swiper
            class="slider"
            ref="swiperRef"
            slides-per-view="auto"
            :space-between="16"
            @swiper="onSwiper"
            :modules="[Navigation]"
        >
          <swiper-slide v-for="(item, id) in blocks" :key="id">
            <IconBlock
              :icon="item.icon"
              :title="item.title"
              :text="item.text"
            />
          </swiper-slide>
        </swiper>
        <div class="control flex">
          <SliderControl
              @click="goPrev"
              :disabled="swiperInstance?.isBeginning"
          ></SliderControl>
          <SliderControl
              @click="goNext"
              btnType="next"
              :disabled="swiperInstance?.isEnd"
          ></SliderControl>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.portfolio-section {
  padding: 100px 0 50px;
  transform: translateY(-100px);
  .section-bg-text {
    text-align: center;
    line-height: 60%;
    font-size: clamp(40px, 21.5vw, 220px);
    letter-spacing: 12.7px;
    top: -6%;
    left: 50%;
    transform: translateX(-50%);
  }
  .portfolio {
    &__top {
      margin-top: 40px;
      justify-content: space-between;
      .icon-block {
        flex: 1 0 50.5%;
        max-width: 636px;
      }
      .texted {
        margin-top: 40px;
        color: $white;
        width: 100%;
        max-width: 526px;
        gap: 20px;
        span {
          display: inline-block;
          white-space: nowrap;
        }
        &__main {
          font-size: 36px;
          line-height: 49px;
          span {
            margin-right: 8px;
          }
        }
        &__secondary {
          font-size: 16px;
          line-height: 28px;
          opacity: 0.7;
          max-width: 483px;
          span {
            margin-right: 4px;
          }
        }
      }
    }
    &__bottom {
      margin-top: 16px;
      gap: 16px;
      & > div {
        width: calc(100% / 4);
      }
    }
    .mob-block {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .portfolio-section {
    .portfolio__bottom {
      flex-wrap: wrap;
      & > div {
        width: calc(50% - 8px);
      }
    }
  }
}

@media (max-width: 768px) {
  .portfolio-section {
    transform: translateY(0);
    padding: 205px 0 0;
    .mob-block {
      display: block !important;
      margin-top: 50px;
    }
    .swiper {
      overflow: clip;

      .swiper-slide {
        width: auto !important;
        height: auto;
        max-width: 300px;
      }
    }
    .control {
      margin: 40px 0 0;
      gap: 18px;
    }
    .portfolio__top {
      margin-top: 48px;
      .texted, .texted__secondary {
        max-width: 100%;
      }
      .texted {
        margin-top: 0;
        &__main {
          font-size: 26px;
          line-height: 36px;
        }
      }
      .icon-block {
        display: none;
      }
    }
    .portfolio__bottom {
      display: none;
    }
    .section-bg-text {
      white-space: break-spaces;
      letter-spacing: 3.7px;
      font-size: clamp(40px, 22vw, 200px);
      top: -12%;
    }
  }
}
</style>