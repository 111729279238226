<script setup>
import {computed, ref} from "vue";

const props = defineProps({
  title: String,
  text: String,
  text2: String,
  image: String,
  textPosition: String,
  big: Boolean,
  width: Number,
  margin: Number,
})

const titleWords = ref(props.title.split(" "));
const textWords = ref(props.text.split(" "));
const text2Words = ref(!!props.text2 & props.text2 !== '' ? props.text2.split(" ") : '');
const textStyle = computed(() => `max-width: ${props.width}px; margin-right: ${props.margin}vw`);
</script>

<template>
  <section
    class="info-section flex justify-between"
    :class="{'row-reverse': textPosition === 'right', 'big': big, 'flex-col': textPosition === 'horizontal', 'align-center': textPosition !== 'horizontal'}"
  >
    <div
        class="left flex flex-col"
        :class="{'flex-row': textPosition === 'horizontal', 'justify-between': big}"
    >
<!--      <h4>{{ title }}</h4>-->
      <h4>
        <span
          v-for="(word, index) in titleWords"
          :key="index"
          class="word"
          v-motion
          :initial="{opacity: 0}"
          :visibleOnce="{opacity: 1, transition: { duration: 1000, delay: index * 50 }}"
        >
          {{ word }}
        </span>
      </h4>
      <p :style="textStyle">
        <span
          v-for="(word, index) in textWords"
          :key="index"
          class="word"
          v-motion
          :initial="{opacity: 0}"
          :visibleOnce="{opacity: 0.7, transition: { duration: 600, delay: 400 + index * 20 }}"
        >
          {{ word }}
        </span>
      </p>
      <p v-if="!!text2 && text2 !== ''" :style="textStyle">
        <span
          v-for="(word, index) in text2Words"
          :key="index"
          class="word"
          v-motion
          :initial="{opacity: 0}"
          :visibleOnce="{opacity: 0.7, transition: { duration: 600, delay: 1200 + index * 20 }}"
        >
          {{ word }}
        </span>
      </p>
    </div>
    <div
      class="right"
      :class="{'horizontal': textPosition === 'horizontal'}"
    >
      <img :src="require(`@/assets/img/${image}`)" alt="section block figure" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.info-section {
  color: $white;
  &.big {
    h4 {
      font-size: 36px;
      font-weight: 400;
      line-height: 49px;
      width: 100%;
      max-width: 550px;
    }
    .right img {
      font-size: 685px;
    }
  }
  &:not(&.big) .left {
    gap: 20px;
  }
  h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    width: 100%;
    max-width: 370px;
    display: flex;
    flex-wrap: wrap;
    span {
      margin-right: 8px;
    }
  }
  span {
    display: inline-block;
    white-space: nowrap;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    margin-right: 5%;
    span {
      opacity: 0.7;
      margin-right: 4px;
    }
  }
  .left {
    gap: 20px;
  }
  .right {
    img {
      font-size: 512px;
      width: 1em;
      height: 1em;
    }
    &.horizontal img {
      margin: -130px 0 0 auto;
      display: block;
    }
  }
}

@media (max-width: 1024px) {
  .info-section {
    &.big {
      h4 {
        font-size: 28px;
        line-height: 32px;
      }
    }
    p {
      margin-right: 0;
    }
    .right {
      &:not(&.horizontal) {
        max-height: 350px;
      }
      img {
        font-size: 440px;
      }
      &.horizontal {
        img {
          font-size: 550px;
          margin: -50px 0 0 auto;
        }
      }
    }
    .left {
      min-width: 50%;
    }
  }
}

@media (max-width: 768px) {
  .info-section {
    flex-direction: column !important;
    &.big {
      h4 {
        max-width: none;
        font-size: 26px;
        font-weight: 400;
        line-height: 46px;
      }
    }
    .left {
      flex-direction: column;
      gap: 40px;
    }
    .right {
      img {
        margin: -40px 0 0;
      }
      &.horizontal {
        display: flex;
        justify-content: center;
        img {
          font-size: clamp(40px, 120vw, 460px);
          margin: 22px 0 0 0;
        }
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
    p {
      max-width: none !important;
    }
  }
}
</style>