<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useRouter } from 'vue-router';
import Logo from './Logo';
import Linkedin from "@/components/additional/Linkedin.vue";
import { useScrollStore } from "@/store/scroll";

const emit = defineEmits(['close']);

const props = defineProps({
  isOpen: Boolean
})

watch(() => props.isOpen, () => {
  if(props.isOpen) document.body.classList.add('no-scroll');
  else document.body.classList.remove('no-scroll');
})

const scrollStore = useScrollStore();
const router = useRouter();

const navs = ref([
  {
    label: "about us",
    block: "about"
  },
  {
    label: "our mission",
    block: "mission"
  },
  {
    label: "our vision",
    block: "vision"
  },
  {
    label: "our services",
    block: "services"
  },
  {
    label: "why choose us?",
    block: "choose"
  },
  {
    label: "our commitment to innovation",
    block: "commitment"
  },
  {
    label: "our trademark portfolio",
    block: "portfolio"
  },
  {
    label: "contacts",
    block: "contacts"
  },
])

const handleClick = (block) => {
  document.body.classList.remove('no-scroll');
  if(router.currentRoute.value.name !== 'home') router.push('/');
  scrollStore.setBlockClicked(true);
  scrollStore.setBlockIndex(block);
  emit('close');
  setTimeout(() => {
    scrollStore.setBlockClicked(false);
    scrollStore.setBlockIndex("");
  }, 500);
}


onUnmounted(() => {
  document.body.classList.remove("no-scroll");
})

</script>

<template>
  <Transition name="mob-menu">
    <div v-if="isOpen" class="menu flex flex-col">
      <div class="container">
        <div class="menu__header flex align-center justify-between">
          <Logo />
          <div class="close" @click="$emit('close')">
            <div class="close-bg" />
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path d="M19.9999 19.9999L11 11M11 11L2 2M11 11L20 2M11 11L2 20" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="wrap flex align-end">
          <div class="menu__body flex flex-col">
            <div
                class="menu-item"
                v-for="(nav, index) in navs"
                :key="index"
                @click="handleClick(nav?.block)"
            >
              {{nav.label}}
            </div>
          </div>
          <div class="menu__footer flex align-end">
            <div class="links flex align-center">
              <a href="mailto:info@ericius.net">info@ericius.net</a>
              <Linkedin />
            </div>
            <div class="address flex align-end">
              <RouterLink to="/privacy">Privacy policy</RouterLink>
              <p>Christodoulou Chadjipavlou 199, 4th Floor, Office 4A, 3036 Limassol, Cyprus</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
.menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.80);
  backdrop-filter: blur(25px);
  padding: 28px 0 13vh;
  color: $white;
  .container {
    height: 100%;
  }
  .close {
    cursor: pointer;
    position: relative;
    &-bg {
      position: absolute;
      border-radius: 18px;
      background: rgba(255, 255, 255, 0.04);
      width: 70px;
      height: 66px;
      opacity: 0;
      transition: all .3s ease;
      top: 44%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      .close-bg {
        opacity: 1;
      }
    }
    svg path {
      transition: all .3s ease;
    }
    &:active {
      svg path {
        stroke: $purple;
      }
    }
  }
  &__body {
    gap: 28px;
    flex: 1 0 50%;
  }
  &__footer {
    gap: 18px;
    .links {
      gap: 18px;
      a:nth-child(2) {
        margin-right: 12px;
      }
    }
    .address {
      gap: 18px;
    }
    a {
      white-space: nowrap;
      transition: color .3s ease;
      &:hover {
        opacity: 1;
        color: rgba(255, 255, 255, 0.70);
      }
      &:active, &:focus {
        opacity: 1;
        color: rgba(255, 255, 255, 1);
      }
    }
    a, p {
      color: rgba(255, 255, 255, 0.50);
      font-size: 14px;
      line-height: 26px;
      text-decoration: none;
    }
    p {
      width: 15vw;
      max-width: 210px;
      text-align: right;
    }
  }
}
.menu-item {
  line-height: 50px;
  cursor: pointer;
  font-size: clamp(14px, 4vw, 46px);
  transition: all .3s ease;
  &:hover {
    color: $purple-300;
  }
  &:active, &:focus {
    color: $purple;
  }
}

.mob-menu-enter-active,
.mob-menu-leave-active {
  transition: transform 0.5s ease;
}

.mob-menu-enter-from,
.mob-menu-leave-to {
  transform: translateX(100%);
}

.mob-menu-enter-to,
.mob-menu-leave-from {
  transform: translateX(0);
}

@media (min-width: 1025px) {
  .menu .container {
    display: flex;
    flex-direction: column;
  }
  .menu .wrap {
    margin: auto 0 0;
  }
}

@media (max-width: 1024px) {
  .menu {
    padding: 28px 0 40px;
    &__body {
      flex: 1 0 100%;
      gap: 4vh;
    }
    .wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 5vh 0 0;
    }
    &__footer {
      width: 100%;
      justify-content: space-between;
      margin-top: auto;
      .address {
        flex-direction: column;
      }
      p {
        width: 100%;
      }
    }
  }
  .menu-item {
    font-size: clamp(14px, 4vh, 38px);
    line-height: 100%;
  }
}

@media (max-width: 768px) {
  .menu {
    &__body {
      gap: 4vh;
      margin: 6vh 0 20px;
      width: 100%;
      flex: 1 0 0;
    }
    .wrap {
      margin: 0;
      height: calc(100% - 40px);
    }
    .close-bg {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }
    .close {
      svg {
        width: 16px;
        height: 16px;
      }
    }
    &__footer {
      .address {
        gap: 16px;
      }
      .links {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-end;
        height: 100%;
      }
    }
    &__footer a, p {
      font-size: 10px;
      line-height: 16px !important;
    }
  }
  .menu-item {
    font-size: 24px;
    line-height: 30px;
  }
}

@media (max-width: 380px) {
  .menu {
    &__body {
      gap: 2vh;
    }
  }
}
</style>