<template>
  <div class="home hidden">
    <Header />
    <Banner />
    <AboutUs ref="about" />
    <OurMission ref="mission" />
    <OurVision ref="vision" />
    <OurServices ref="services" />
    <WhyChooseUs ref="choose" />
    <OurCommitment ref="commitment" />
    <OurPortfolio ref="portfolio" />
    <NoteSection />
    <Footer ref="contacts" />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import Header from "@/components/common/Header.vue";
import Banner from "@/components/sections/Banner.vue";
import AboutUs from "@/components/sections/AboutUs.vue";
import OurMission from "@/components/sections/OurMission.vue";
import OurVision from "@/components/sections/OurVision.vue";
import OurServices from "@/components/sections/OurServices.vue";
import WhyChooseUs from "@/components/sections/WhyChooseUs.vue";
import OurCommitment from "@/components/sections/OurCommitment.vue";
import OurPortfolio from "@/components/sections/OurPortfolio.vue";
import NoteSection from "@/components/sections/NoteSection.vue";
import Footer from "@/components/common/Footer.vue";
import {useScrollStore} from "@/store/scroll";

const navigationStore = useScrollStore();

const about = ref(null);
const mission = ref(null);
const vision = ref(null);
const services = ref(null);
const choose = ref(null);
const commitment = ref(null);
const portfolio = ref(null);
const contacts = ref(null);

watch(() => navigationStore.blockIndex, (newValue, oldValue) => {
  if (navigationStore.blockClicked) {
    nextTick(() => {
      scrollToBlock(newValue);
    });
  }
}, {deep: true})

const getBlockRef = (index) => {
  switch(index) {
    case 'about':
      return about.value;
    case 'mission':
      return mission.value;
    case 'services':
      return services.value;
    case 'vision':
      return vision.value;
    case 'portfolio':
      return portfolio.value;
    case 'choose':
      return choose.value;
    case 'commitment':
      return commitment.value;
    case 'contacts':
      return contacts.value;
  }
}

const scrollToBlock = (blockIndex) => {
  if(blockIndex !== 'products') {
    const blockRef = getBlockRef(blockIndex);
    if (blockRef && blockRef.$el) {
      blockRef.$el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

onMounted(() => {
  if(navigationStore.blockIndex === 0) navigationStore.setBlockIndex('home');
  if (navigationStore.blockClicked) {
    setTimeout(() => {
      scrollToBlock(navigationStore.blockIndex)
    }, 0);
  }
})

</script>
