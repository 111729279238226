<script setup>

</script>

<template>
  <div class="burger">
    <div class="burger-back"/>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22" fill="none">
      <line y1="1" x2="30" y2="1" stroke="white" stroke-width="2"/>
      <line y1="11" x2="30" y2="11" stroke="white" stroke-width="2"/>
      <line y1="21" x2="30" y2="21" stroke="white" stroke-width="2"/>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.burger {
  cursor: pointer;
  position: relative;
  &-back {
    position: absolute;
    border-radius: 18px;
    background: rgba(255, 255, 255, 0.04);
    width: 70px;
    height: 66px;
    opacity: 0;
    transition: all .3s ease;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    border-radius: 18px;
    background: rgba(255, 255, 255, 0.04);
    .burger-back {
      opacity: 1;
    }
  }
  svg line {
    transition: all .3s ease;
  }
  &:active {
    svg line {
      stroke: $purple;
    }
  }
}
@media (max-width: 768px) {
  .burger {
    height: 16px;
    &-back {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }
    svg {
      height: 16px;
      width: 22px;
    }
  }
}
</style>