<script setup>
import {ref} from "vue";

const props = defineProps({
  title: String,
  text: String,
  reverse: Boolean
})

const textWords = ref(props.text.split(" "));
</script>

<template>
  <div class="text-block flex flex-col" :class="{'second': !reverse}">
    <h5
      v-motion
      :initial="{opacity: 0, y: -10}"
      :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 100 }}"
    >{{ title }}</h5>
    <p>
      <span
        v-for="(word, index) in textWords"
        :key="index"
        class="word"
        v-motion
        :initial="{opacity: 0}"
        :visibleOnce="{opacity: 0.7, transition: { duration: 1000, delay: 200 + index * 20 }}"
      >
        {{ word }}
      </span>
    </p>
  </div>
</template>

<style scoped lang="scss">
.text-block {
  gap: 20px;
  color: $white;
  padding: 0 0 8%;
  &.second {
    padding: 10% 0 0;
  }
  h5 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    span {
      display: inline-block;
      white-space: nowrap;
      margin-right: 4px;
      opacity: 0.7;
    }
  }
}

@media (max-width: 768px) {
  .text-block {
    margin: 0 !important;
    padding: 0;
    &.second {
      padding: 0;
    }
    h5 {
      font-size: 20px;
      line-height: 28px;
    }
    p {
      opacity: 0.8;
    }
  }
}
</style>