<script setup>
import { ref } from "vue";
import Button from "@/components/ui/Button.vue";
import Modal from "@/components/ui/Modal.vue";

const isModal = ref(false);
</script>

<template>
  <section class="banner">
    <div class="container">
      <div class="circle-bg"></div>
      <div class="first flex justify-between align-end">
        <p
          v-motion
          :initial="{opacity: 0, y: -30}"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 1000, delay: 100 }}"
        >welcome</p>
        <div class="images flex">
          <div
            class="img-wrap"
            v-motion
            :initial="{opacity: 0, y: -10}"
            :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 300 }}"
          >
            <img src="@/assets/img/banner1.jpg" alt="banner image"/>
          </div>
          <div
            class="img-wrap"
            v-motion
            :initial="{opacity: 0, y: -30}"
            :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 400 }}"
          >
            <img src="@/assets/img/banner2.jpg" alt="banner image"/>
          </div>
          <div
            class="img-wrap"
            v-motion
            :initial="{opacity: 0, y: -30}"
            :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 500 }}"
          >
            <img src="@/assets/img/banner3.jpg" alt="banner image"/>
          </div>
        </div>
      </div>
      <div class="second flex justify-between">
        <div class="second__left flex align-end">
          <div
            class="img-wrap"
            v-motion
            :initial="{opacity: 0, y: -30}"
            :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 500 }}"
          >
            <img src="@/assets/img/banner4.jpg" alt="banner image" />
          </div>
          <p
            v-motion
            :initial="{opacity: 0, y: -30}"
            :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 600 }}"
          >to</p>
        </div>
        <p
          v-motion
          :initial="{opacity: 0, y: -30}"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 600 }}"
        >Ericius</p>
      </div>
      <div class="third flex justify-between align-end">
        <div
          class="image"
          v-motion
          :initial="{opacity: 0, y: -30}"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 700 }}"
        >
          <img src="@/assets/img/banner5.jpg" alt="banner image" />
        </div>
        <p
          v-motion
          :initial="{opacity: 0, y: -30}"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 800 }}"
        >Investments</p>
      </div>
      <p
        class="text"
        v-motion
        :initial="{opacity: 0}"
        :visibleOnce="{opacity: 0.8, transition: { duration: 900, delay: 900 }}"
      >Where we are dedicated to safeguarding intellectual property rights and fostering innovation</p>
      <Button
        @click="isModal = true"
        v-motion
        :initial="{opacity: 0}"
        :visibleOnce="{opacity: 1, transition: { duration: 900, delay: 900 }}"
      >Get in Touch</Button>
    </div>
    <Modal :isOpen="isModal" @close="isModal = false"></Modal>
  </section>
</template>

<style scoped lang="scss">
.banner {
  position: relative;
  padding: 60px 0;
  color: $white;
  .circle-bg {
    border-radius: 536px;
    background: radial-gradient(97.2% 97.2% at 50% 50%, rgba(147, 40, 175, 0.59) 0%, rgba(61, 17, 73, 0.00) 100%);
    filter: blur(80px);
    font-size: 30vw;
    width: 1em;
    height: 1em;
    position: absolute;
    z-index: 0;
    top: 10%;
    left: 27%;
  }
  .text {
    font-size: 21px;
    line-height: 35px;
    letter-spacing: 0.42px;
    opacity: 0.8;
    margin-top: 4px;
  }
  button {
    margin: 44px 0 0 auto;
  }
  img {
    display: block;
    flex-shrink: 0;
    max-width: 100%;
    height: 100%;
    width: 100%;
    max-height: 111px;
  }
  p {
    letter-spacing: 5px;
  }
  .first {
    position: relative;
    z-index: 1;
    gap: 20px;
    overflow: hidden;
    p {
      font-size: clamp(40px, 15vw, 210px);
      font-weight: 700;
      line-height: 100%;
      font-variant: all-small-caps;
    }
    .images {
      gap: 8px;
      margin-bottom: 2.3vw;
      .img-wrap {
        width: calc(100% / 3 - 6px);
        max-width: 170px;
      }
    }
  }
  .second {
    position: relative;
    z-index: 1;
    margin-top: -20px;
    &__left {
      gap: 18px;
    }
    .img-wrap {
      width: 50%;
      max-width: 150px;
      margin-bottom: 4%;
    }
    p {
      font-family: "Playfair Display", sans-serif;
      font-size: clamp(40px, 14vw, 160px);
      font-style: italic;
      font-weight: 600;
      line-height: 100%;
    }
  }
  .third {
    position: relative;
    z-index: 1;
    margin-top: -3%;
    gap: 18px;
    .image {
      width: 30%;
      max-width: 216px;
      margin-bottom: 2vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      font-size: clamp(40px, 14vw, 210px);
      line-height: 100%;
      font-variant: all-small-caps;
    }
  }
}

@media (max-width: 1280px) {
  .banner {
    .third {
      margin-top: 0;
      p {
        font-size: clamp(40px, 13vw, 210px);
      }
    }
  }
}

@media (max-width: 1024px) {
  .banner {
    .second {
      margin-top: 10px;
    }
    .third {
      margin-top: 10px;
      p {
        font-size: clamp(40px, 12vw, 210px);
      }
    }
  }
}

@media (max-width: 768px) {
  .banner {
    padding: 56px 0 40px;
    img {
      object-fit: cover;
    }
    .first {
      gap: 12px;
      .images {
        max-height: 8.3vw;
        gap: 0;
        & > div:nth-child(1), & > div:nth-child(2) {
          display: none;
        }
        & > div:nth-child(3) {
          width: 100%;
          object-fit: cover;
        }
      }
      p {
        font-size: clamp(40px, 14vw, 70px);
        line-height: 110%;
        font-weight: 900;
      }
    }
    .second {
      .img-wrap {
        max-width: 35px;
        height: 100%;
        max-height: 41px;
        object-position: 90% center;
      }
      &__left {
        gap: 10px;
      }
      p {
        letter-spacing: 0;
        font-size: clamp(30px, 15.1vw, 58px);
      }
    }
    .third {
      .image {
        min-width: 24px;
        width: 7%;
        max-height: 35px;
        overflow: hidden;
        object-fit: cover;
        img {
          width: 123px;
          max-width: none;
          transform: translate(-43%, -7%);
        }
      }
      p {
        font-size: clamp(40px, 13.5vw, 70px);
        line-height: 110%;
        font-weight: 700;
      }
    }
    .text {
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.28px;
      margin-top: 12px;
    }
    button {
      margin: 40px 0 0;
      max-width: 100%;
    }
    .circle-bg {
      font-size: 60vw;
      top: 8%;
      left: 11%;
      max-width: 380px;
      max-height: 380px;
    }
  }
}
</style>