<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import SectionTitle from "@/components/additional/SectionTitle.vue";
import InfoCard from "@/components/additional/InfoCard.vue";
import 'swiper/css';
import SliderControl from "@/components/additional/SliderControl.vue";

const infos = ref([
  {
    image: "sphere1.png",
    text: "At Ericius Investments, innovation is at the heart of everything we do. We understand that in today's fast-paced world, staying ahead requires protecting your intellectual property and continuously pushing the boundaries of what's possible"
  },
  {
    image: "sphere2.png",
    text: "Ericius Investments is dedicated to fostering an environment where creativity and innovation thrive. We provide our beneficiaries with the latest tools and resources to turn their ideas into reality, ensuring they remain competitive in their industries"
  },
  {
    image: "sphere3.png",
    text: "By integrating advanced technologies and staying abreast of emerging trends, we help our clients transform their innovative concepts into valuable assets"
  },
])

const swiperInstance = ref(null);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};
</script>

<template>
  <section class="section-block section-commitment">
    <div class="container relative">
      <SectionTitle>our commitment to innovation</SectionTitle>
      <p class="section-bg-text">our commitment<br/>to innovation</p>
      <div class="infos flex justify-between">
        <InfoCard
          v-for="(info, index) in infos"
          :key="index"
          :img="info.image"
          :text="info.text"
          v-motion
          :initial="{opacity: 0, x: -50}"
          :visibleOnce="{opacity: 1, x: 0, transition: { duration: 1000, delay: 200 }}"
        />
      </div>
      <div class="mob-block">
        <swiper
            class="slider"
            ref="swiperRef"
            slides-per-view="auto"
            :space-between="16"
            @swiper="onSwiper"
            :modules="[Navigation]"
        >
          <swiper-slide v-for="(info, id) in infos" :key="id">
            <InfoCard
              :img="info.image"
              :text="info.text"
            />
          </swiper-slide>
        </swiper>
        <div class="control flex">
          <SliderControl
              @click="goPrev"
              :disabled="swiperInstance?.isBeginning"
          ></SliderControl>
          <SliderControl
              @click="goNext"
              btnType="next"
              :disabled="swiperInstance?.isEnd"
          ></SliderControl>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.section-commitment {
  padding: 138px 0 73px;
  transform: translateY(-100px);
  .container {
    overflow: visible;
  }
  .section-bg-text {
    letter-spacing: 15px;
    line-height: 60%;
    text-align: center;
    font-size: clamp(40px, 14.5vw, 200px);
    top: -7%;
    left: 50%;
    transform: translateX(-50%);
  }
  .mob-block {
    display: none;
  }
  .infos {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: rgba(255, 255, 255, 0.04);
    padding: 83px 70px 73px;
    margin-top: 80px;
  }
  .swiper {
    overflow: clip;

    .swiper-slide {
      width: auto !important;
      height: auto;
      max-width: 300px;
    }
  }
}

@media (max-width: 1280px) {
  .section-commitment {
    .infos {
      padding: 70px 40px;
    }
  }
}

@media (max-width: 768px) {
  .section-commitment {
    padding: 130px 0 10px;
    transform: translateY(0);
    .section-bg-text {
      max-width: 100%;
      white-space: break-spaces;
      letter-spacing: 3.2px;
      font-size: clamp(40px, 18vw, 280px);
      top: -22%;
      line-height: 80%;
    }
    .mob-block {
      display: block;
      border-radius: 20px;
      border: 1px solid rgba(255, 255, 255, 0.04);
      background: rgba(255, 255, 255, 0.04);
      padding: 0px 24px 24px;
      margin-top: 34px;
      .control {
        margin: 40px 0 0;
        gap: 18px;
        .slide-control-btn {
          font-size: 60px;
        }
      }
    }
    .infos {
      display: none;
    }
    .swiper {
      overflow: clip;
      padding-top: 80px;

      .swiper-slide {
        width: 100% !important;
        height: auto;
        max-width: none;
      }
    }
  }
}
</style>