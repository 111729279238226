import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import { MotionPlugin } from '@vueuse/motion';
import "@/assets/style/style.scss"

const app = createApp(App)
const pinia = createPinia();

app.use(router).use(pinia).use(MotionPlugin).mount('#app');