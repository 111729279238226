<script setup>
import { ref } from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import SectionTemplate from "@/components/additional/SectionTemplate.vue";

const data = ref({
  title: "Our expertise extends across Trademarks, Patents, and Industrial Designs, ensuring comprehensive monitoring and management of these vital resources",
  text: "As a leading company in the industry, our primary focus is on providing top-notch information and support to ensure the protection and growth of our beneficiaries' intellectual property assets",
  image: "figure1.png",
  textPosition: "horizontal",
})
</script>

<template>
  <section class="section-block about-section">
    <div class="container relative">
      <SectionTitle>about us</SectionTitle>
      <SectionTemplate
          :title="data.title"
          :image="data.image"
          :textPosition="data.textPosition"
          :text="data.text"
          :width="425"
          big
      />
      <p class="section-bg-text">about us</p>
    </div>
  </section>
</template>

<style lang="scss">
.about-section {
  padding: 110px 0 0;
  .container {
    overflow: visible;
  }
  .section-bg-text {
    font-size: clamp(40px, 25vw, 390px);
    letter-spacing: 26px;
    top: -10%;
    left: 50%;
    transform: translateX(-50%);
  }
  .info-section {
    padding: 80px 0 0;
  }
}

@media (max-width: 768px) {
  .about-section {
    .info-section {
      padding: 48px 0 0;
    }
    .section-bg-text {
      letter-spacing: 4.5px;
      font-size: clamp(40px, 28.5vw, 390px);
      top: -8%;
    }
  }
}
</style>