<script setup>
import {ref, watch} from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import Form from "@/components/ui/Form.vue";
import Logo from "@/components/common/Logo.vue";
import Linkedin from "@/components/additional/Linkedin.vue";
import Modal from "@/components/ui/Modal.vue";

const isSuccess = ref(false);
const isSent = ref(false);

const getResponse = (val) => {
  isSent.value = true;
  isSuccess.value = val;
}
</script>

<template>
  <footer>
    <div class="footer-top">
      <div class="container flex justify-between">
        <div class="hidden absolute">
          <p class="section-bg-text">contact us</p>
        </div>
        <div class="top">
          <SectionTitle>contact us</SectionTitle>
          <div class="footer-info flex flex-col">
            <p
              v-motion
              :initial="{opacity: 0, y: -4}"
              :visibleOnce="{opacity: 1, y: 0, transition: { duration: 1000, delay: 100 }}"
            >Christodoulou Chadjipavlou 199, 4th Floor, Office 4A, 3036 Limassol, Cyprus</p>
            <a
              href="mailto:info@ericius.net"
              v-motion
              :initial="{opacity: 0, y: -4}"
              :visibleOnce="{opacity: 1, y: 0, transition: { duration: 1000, delay: 150 }}"
            >info@ericius.net</a>
            <Linkedin
                in-footer
              v-motion
              :initial="{opacity: 0, y: -4}"
              :visibleOnce="{opacity: 1, y: 0, transition: { duration: 1000, delay: 200 }}"
            />
          </div>
        </div>
        <div
          class="form-wrap"
          v-motion
          :initial="{opacity: 0}"
          :visibleOnce="{opacity: 1, transition: { duration: 1200, delay: 200 }}"
        >
          <Form @response="getResponse"/>
        </div>

      </div>
    </div>
    <div class="footer-bottom">
      <div class="container flex align-center justify-between">
        <div
          class="bottom flex align-center"
          v-motion
          :initial="{opacity: 0, y: 10}"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 1000, delay: 300 }}"
        >
          <p class="copyright">Ⓒ 2024, Ericius Investments Limited</p>
          <RouterLink to="/privacy">Privacy policy</RouterLink>
        </div>
        <RouterLink to="/" class="logo-a">
          <Logo
              :width="115" :height="34"
              v-motion
              :initial="{opacity: 0, y: 10}"
              :visibleOnce="{opacity: 1, y: 0, transition: { duration: 1000, delay: 200 }}"
          />
        </RouterLink>
      </div>
    </div>
    <Modal :isOpen="isSent" fromChild :isSuccessChild="isSuccess" :isSentChild="isSent" @close="isSent = false"></Modal>
  </footer>
</template>

<style lang="scss">
footer {
  background: #131313;
  position: relative;
  margin-top: 60px;
  .hidden.absolute {
    top: -8%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    width: 100%;
    height: 23vw;
  }
  .section-bg-text {
    font-size: clamp(40px, 23vw, 315px);
    letter-spacing: 12.7px;

  }
  .f-form {
    transform: translateY(-115px);
  }
  .top {
    padding-top: 68px;
  }
  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    padding: 26px 0;
  }
  .bottom {
    gap: 38px;
  }
  p, & a:not(&.logo-a), & a:not(&.logo-a):visited {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
  }
  a, .foot {
    transition: all .3s ease;
    &:hover {
      color: $purple-300 !important;
    }
    &:active, &:focus {
      color: $purple !important;
    }
  }
}

.footer-info {
  width: 100%;
  max-width: 210px;
  gap: 26px;
  margin-top: 80px;
}

@media (max-width: 768px) {
  footer {
    margin-top: 30px;
    .hidden.absolute {
      top: auto;
      bottom: 25%;
    }
    .section-bg-text {
      font-size: clamp(40px, 22vw, 200px);
      letter-spacing: 3.5px;

    }
    .container {
      flex-direction: column-reverse;
    }
    .f-form {
      transform: translateY(-53px);
    }
    .top {
      padding-top: 5px;
    }
    .footer-info {
      margin-top: 40px;
      gap: 20px;
    }
    p, a:not(&.logo-a) {
      line-height: 26px;
    }
    .footer-bottom {
      border-top: none;
      .container > .logo-a {
        display: none;
      }
      .bottom {
        flex-direction: row-reverse;
        opacity: 0.5;
        width: 100%;
        justify-content: space-between;
        gap: 8px;
        flex-wrap: wrap;
        a:not(&.logo-a) {
          white-space: nowrap;
        }
      }
    }

  }
}
</style>